import React  from "react"

const Layout = ({ children, pageContext }) => {
  return (
    <div className="flex flex-col justify-between min-h-screen">
      {/* Aligned to the top */}
      <div className="flex-1">
        <div>{children}</div>
      </div>
      {/* Aligned to the bottom */}
    </div>
  )
}

export default Layout
